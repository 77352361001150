import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
	AuthenticationResult,
	EventMessage,
	EventType,
	InteractionRequiredAuthError,
	InteractionStatus,
	InteractionType,
} from '@azure/msal-browser';
import {
	filter,
	map,
	Observable,
	skipWhile,
	startWith,
	Subscription,
} from 'rxjs';
import { InactivityComponent } from './core/components/inactivity/inactivity.component';
import { PharmacySettingsService } from './core/services/pharmacy-settings.service';
import { PrintService } from './core/services/print.service';
import { SignalRService } from './core/services/signal-r.service';
import { AuthService } from './views/auth/auth.service';

@Component({
    selector: 'app-root',
    template: `
		<router-outlet />
		@if (inactivityEnabled$ | async) {
			<app-inactivity />
		}
	`,
    imports: [RouterOutlet, InactivityComponent, AsyncPipe]
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'PickupAssistantClient';
	private _isAuthenticated: boolean = false;
	inactivityEnabled$: Observable<boolean>;
	subs = new Subscription();

	constructor(
		private signalRService: SignalRService,
		public printService: PrintService,
		private authService: AuthService,
		private msalService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private pharmacySettings: PharmacySettingsService,
	) {
		this.inactivityEnabled$ =
			this.pharmacySettings.onPharmacySettingsChanged.pipe(
				skipWhile((x) => x == null),
				map((x) => x.enableInactivity),
				startWith(false),
			);
	}

	private isDebugEnabled(): boolean {
		return localStorage.getItem('puc:debugmsal') != null;
	}

	ngOnInit(): void {
		this.initMsalEvents();

		if (this.isDebugEnabled()) window['msal'] = this.msalService;

		this.subs.add(
			this.authService.isAuth$.subscribe((isAuth: boolean) => {
				if (isAuth != this._isAuthenticated) this.onAuthChanged(isAuth);
			}),
		);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	onAuthChanged(newState: boolean): void {
		this._isAuthenticated = newState;
		this.signalRService.stopConnection().then(() => {
			if (newState) {
				this.signalRService.startConnection();
			}
		});
		if (newState) this.pharmacySettings.loadPharmacySettings();
	}

	initMsalEvents(): void {
		this.subs.add(
			this.msalBroadcastService.inProgress$
				.pipe(
					filter(
						(status: InteractionStatus) =>
							status === InteractionStatus.None,
					),
				)
				.subscribe(() => {
					if (this.isDebugEnabled()) {
						let tmp = 'msal:event\n';
						for (let i = 0; i < localStorage.length; i++)
							tmp += localStorage.key(i) + '\n';
						console.error(tmp);
					}
				}),
		);

		// handle auth redired/do all initial setup for msal
		this.subs.add(
			this.msalService.handleRedirectObservable().subscribe((auth) => {
				// Check if user signed in

				if (auth !== null && auth.accessToken === null) {
					if (this.isDebugEnabled()) {
						console.log(auth);
					}
				}
			}),
		);

		this.msalService.instance.enableAccountStorageEvents();

		this.subs.add(
			this.msalBroadcastService.msalSubject$.subscribe(
				(result: EventMessage) => {
					if (this.isDebugEnabled()) {
						let tmp = 'msal:event\n';
						for (let i = 0; i < localStorage.length; i++)
							tmp += localStorage.key(i) + '\n';
						console.error(tmp, result);
					}

					this.handleResponse(result);
				},
			),
		);
	}

	private aquireFailed(msg: string): void {
		this.authService.logout();
		this.authService.msalAquireTokenFailed = true;
	}

	private handleResponse(result: EventMessage) {
		if (result.error instanceof InteractionRequiredAuthError) {
			this.msalService.instance
				.handleRedirectPromise()
				.then((res) => {
					this.msalService.instance.logout();
					if (res !== null && res.accessToken === null) {
						console.log(res);
					}

					this.msalService.instance.setActiveAccount(res.account);
				})
				.catch((err) => {
					console.log(err);
				});
			return;
		}

		if (result.eventType === EventType.ACCOUNT_REMOVED) {
			// user logged out in another tab
		} else if (
			result.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
			result.interactionType !== InteractionType.Silent
		) {
			this.aquireFailed('aquire token failured');
		} else if (
			result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
			result.interactionType !== InteractionType.Silent
		) {
			if ((result.payload as any).accessToken == '')
				this.aquireFailed(
					"no access token in payload (shouldn't happen)",
				);
		}

		if (
			(result.eventType === EventType.LOGIN_SUCCESS ||
				result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
			result.payload != null
		) {
			const payload = result.payload as AuthenticationResult;
			this.msalService.instance.setActiveAccount(payload.account);
		}
	}
}
