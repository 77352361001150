import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { GuestNavComponent } from '../../navbar/guest-nav/guest-nav.component';
import { ButtonComponent } from 'src/app/core/components/button.component';

@Component({
    selector: 'app-login-failed',
    template: `
		<app-guest-nav></app-guest-nav>

		<div class="row">
			<div class="text-center col">
				<p class="mb-6">
					{{ 'AUTH.LOGIN_FAILED.ERROR' | translate }}
				</p>
				<app-button
					text="AUTH.LOGIN_FAILED.RETRY"
					(click)="loginAgain()"
				/>
				<app-button
					(click)="logoutFull()"
					text="AUTH.LOGIN_FAILED.LOGOUT"
				/>
			</div>
		</div>
	`,
    imports: [GuestNavComponent, TranslateModule, ButtonComponent]
})
export class LoginFailedComponent {
	constructor(private authService: AuthService) {}

	loginAgain(): void {
		window.location.replace('/');
	}

	logoutFull(): void {
		this.authService.logout();
		window.location.replace('/logout');
	}
}
