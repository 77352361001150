import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
	ARTICLES_PER_SIDE,
	PrintService,
} from 'src/app/core/services/print.service';
import { ArticlePdfComponent } from '../../../core/components/article-pdf/article-pdf.component';

@Component({
    selector: 'app-article-pdf-print',
    template: `
		<div>
			<app-article-pdf
				[articlesMatrix]="articlesMatrix"
				[dispensedArticles]="dispensedArticles"
			/>
		</div>
	`,
    styles: [
        `
			@media screen {
				:host {
					display: none;
				}
			}
		`,
    ],
    imports: [ArticlePdfComponent]
})
export class OutOfStockArticlePdfPrintComponent implements OnDestroy {
	subs: Subscription = new Subscription();

	articlesMatrix: any[];
	dispensedArticles: boolean;

	constructor(private printService: PrintService) {
		this.subs.add(
			this.printService.articlePdfPrint.subscribe(
				async (articlePdfPrint) => {
					this.dispensedArticles = articlePdfPrint.dispensedArticles;
					this.articlesMatrix = this.printService.listToMatrix(
						await Promise.all(
							articlePdfPrint.articles.map(async (x) => {
								return {
									barcode:
										await this.printService.createBarCode(
											x.articleCode,
										),
									...x,
								};
							}),
						),
						ARTICLES_PER_SIDE,
					);
					this.printService.isPrintingArticlePdf$.next(true);
					this.printService.onDataReady(
						this.printService.isPrintingArticlePdf$,
					);
				},
			),
		);
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}
}
