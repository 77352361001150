import { Component, OnInit } from '@angular/core';
import { PrintWrapperComponent } from '../../print/print-wrapper/print-wrapper.component';
import { SideNavComponent } from '../../navbar/side-nav/side-nav.component';
import { NavbarComponent } from '../../navbar/navbar.component';

@Component({
    selector: 'app-wrapper',
    template: `
		<app-navbar></app-navbar>
		<app-side-nav></app-side-nav>
		<app-print-wrapper></app-print-wrapper>
	`,
    imports: [NavbarComponent, SideNavComponent, PrintWrapperComponent]
})
export class AppWrapperComponent {}
